import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import ExplanationMessage from "../ExplanationMessage";
import ControlBar, { IValueState } from "../ControlBar/ControlBar";
import TestsContainer from "../TestsContainer/TestsContainer";
import AdditionalTestsContainer from "../TestsContainer/AdditionalTestsContainer";
import ProgressBar from "../ProgressBar/ProgressBar";
import logo from "../../assets/images/logo.jpg";
import PrintInfoBox from "../PrintInfoBox";
import LogsComponent from "../LogModal/logs";
import "./MainContent.scss";
import { LogModalProvider } from "../LogModal/LogModalProvider";
import PermissionModal from "../PermissionModal";
import useExtensionTracker, { extensionId } from "../../helpers/customHooks/useExtensionTracker";
import CompanionModal from "../CompanionModal/companionModal";
import { ILog, ITestsResult } from "twillio-tests/core/TestResults";
import { Typography, withTheme } from "@material-ui/core";
import { ChevronDownIcon, InfoIcon, LogErrorIcon, LogWarningIcon, OpenInNewIcon } from "../../assets/icons";
import { IField } from "../../interfaces";
import { getCompanionConfig, getQueryParameters } from "../../helpers/utils";
import { startTest } from "../../actions/actions";
import ScoreDonutChart from "../TestsContainer/Tests/NewTests/UI/ScoreDonutChart";
import { StatusCodes } from "../../helpers/statusCodes";
import { useTranslation } from "react-i18next";
import TestStatusChart from "../TestsContainer/Tests/NewTests/UI/TestStatusChart";
import Information from "../UI/Information";
import LoadingSpin from "../UI/LoadingSpin";
import TestReloadButton from "../UI/TestReloadButton";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import MessageModal from "../MessageModal/MessageModal";
import { colors } from "../App/CustomTheme";
import FailedBrowser from "./FailedBrowser";

const styles = (theme: any): any => ({
  wrapper: {},
  container: {
    maxWidth: 1230,
    margin: "0 auto",
    width: "100%",
    padding: "0 15px",
  },
  powered: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 16,
  },
  powered_avatour: {
    width: 200,
    margin: "0 auto",
    paddingBottom: 0,
    background: "#fff",
  },
  poweredText: {
    fontWeight: 600,
    color: "#333",
    fontSize: 10,
  },
  poweredLogo: {
    width: 47,
    height: "auto",
    display: "block",
    marginLeft: 4,
  },
  lobbyWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "1230px",
    margin: "0 auto",
    padding: "0 24px",
    width: "100%",
    paddingBottom: "32px",
    marginTop: "32px",
  },
  lobbyPage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  actionBtnSection: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  actionBtn: {
    padding: "8px 22px",
    borderRadius: "4px",
    color: theme.newPalette.primary.contrast,
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "26px",
    letterSpacing: "0.46px",
    textTransform: "uppercase",
    border: "none",
    // backgroundColor: theme.customColors.mainColor,
    backgroundColor: theme.newPalette.primary.testrtc.main,
  },
  whatIsThisText: {
    textDecoration: "none",
    textAlign: "center",
    color: "#3577C1",
  },
  poweredBy: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    paddingBottom: "32px",
    paddingTop: "32px",
    "& span": {
      color: theme.newPalette.text.primary,
      fontSize: "12px",
      letterSpacing: "0.4px",
      fontWeight: 400,
      lineHeight: "19.92px",
    },
  },
  formSection: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  formDesc: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    "& h1": {
      color: theme.newPalette.text.primary,
      fontSize: "30px",
      fontWeight: 700,
      lineHeight: "37.5px",
      textAlign: "center",
      // fontFamily: "Roboto, sans-serif",
    },
    "& h6": {
      color: theme.newPalette.text.primary,
      maxWidth: "480px",
      textAlign: "center",
      fontSize: "16px",
      lineHeight: "28px",
      letterSpacing: "0.15px",
      // fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
    },
  },
  formInputs: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    "& input": {
      padding: "5.5px 0",
      // fontFamily: "Roboto, sans-serif",
    },
    "& label": {
      // fontFamily: "Roboto, sans-serif",
    },
  },
  insightWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    maxWidth: "1230px",
    margin: "0 auto",
    width: "100%",
    flex: 1,
  },
  insightContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    padding: "0 24px",
    "@media (min-width: 1070px)": {
      "& > :not(:first-child)": {
        borderRightWidth: "0px",
        borderLeftWidth: "1px",
        borderLeft: `1px solid ${theme.newPalette.other.divider}`,
      },
    },
    "@media (max-width: 1070px)": {
      flexDirection: "column",
    },
  },
  logContainer: {
    maxWidth: "797px",
    width: "100%",
    paddingLeft: "24px",
    display: "flex",
    flexDirection: "column",
    flexShrink: "0",
    "& > :not(:first-child)": {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderTop: `1px solid ${theme.newPalette.other.divider}`,
    },
    "@media (max-width: 1070px)": {
      maxWidth: "max-content",
      paddingLeft: 0,
    },
  },
  chartContainer: {
    width: "100%",
    paddingRight: "24px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  suggestionContainer: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    padding: "16px",
    "@media (max-width: 700px)": {
      padding: "16px 0",
    },
    "&:hover": {
      background: theme.newPalette.hoverSuggestion,
      boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)",
      borderRadius: "4px",
    },
    transitionProperty: "all",
    transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
    transitionDuration: "200ms",
  },
  suggestionText: {
    display: "flex",
    flex: 1,
    color: `${theme.newPalette.text.primary} !important`,
  },
  btnOverride: {
    border: "none",
    padding: 0,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    "&:disabled": {
      backgroundColor: "transparent",
    },
    cursor: "pointer",
  },
  chart: {
    fill: "blue",
    stroke: "black",
    strokeWidth: "10px",
  },
  showBtnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  errorChart: {
    fill: theme.newPalette.error.shades,
    stroke: theme.newPalette.error.main,
  },
  warningChart: {
    fill: theme.newPalette.warning.shades,
    stroke: theme.newPalette.warning.main,
  },
  successChart: {
    fill: theme.newPalette.chartSuccess.shades,
    stroke: theme.newPalette.chartSuccess.main,
  },
  chartTitle: {
    fontSize: "38px",
    fontWeight: 700,
    letterSpacing: "-0.5px",
    lineHeight: "57px",
  },
  insightInfo: {
    padding: "16px 24px 0 24px",
    "& p": {
      color: theme.newPalette.text.secondary,
    },
  },
  showBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    padding: "4px 3px 4px 5px",
    color: theme.newPalette.secondary.main,
    fontWeight: 500,
    textTransform: "uppercase",
    transitionProperty: "all",
    transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
    transitionDuration: "200ms",
  },
  openNewIcon: {
    padding: "4px",
    borderRadius: "9999px",
    "&:hover": {
      background: "rgba(36, 53, 68, 0.04)",
    },
    cursor: "pointer",
    color: theme.newPalette.action.active,
  },
  loadingSpin: {
    height: "330px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "24px",
    "& p": {
      color: theme.newPalette.text.secondary,
    },
  },

  statusChartContainer: {
    marginTop: "32px",
    width: "100%",
    paddingRight: "24px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  backgroundColor: {
    background: theme.newPalette.background,
    flex: 1,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    width: "100%",
    flex: 1,
  },
  valueColor: {
    color: theme.newPalette.text.primary,
  },
});

function mapStateToProps(state: any) {
  return {
    backgroundColor: state.document.layout.backgroundColor,
    hidePoweredByRTC: state.document.layout.hidePoweredByRTC,
    storeTheme: state.document.theme,
    config: state.tests.config,
    testIsLoading: state.document.testIsLoading,
    testResult: state.tests.testResult,
    logs: state.tests.logs,
    isReady: state.tests.isReady,
    createDate: state.tests.createDate,
    uuid: state.document.uuid,
    email: state.tests.email,
    fields: state.document.fields || [],
    tests: state.tests,
    testsList: state.tests.testsList,
    scoringResult: state.tests.scoringResult,
    rulesEngineResult: state.tests.rulesEngineResult,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    onStartTests(testsList: string[], config: any) {
      dispatch(startTest(testsList, config));
    },
  };
}

interface IMainContentProps {
  classes: any;
  backgroundColor?: string;
  hidePoweredByRTC?: boolean;
  storeTheme?: any;
  config?: TestConfiguration;
  testIsLoading?: boolean;
  testResult?: ITestsResult;
  logs?: ILog[];
  isReady?: boolean;
  createDate?: any;
  uuid?: string;
  email?: string;
  fields?: IField[];
  tests?: any;

  onStartTests(testsList: string[], config: any): void;

  testsList: string[];
  scoringResult: any;
  rulesEngineResult: RulesEngineResult;
  downloadPdf?: () => void;
  theme?: any;
}

const wrapperStyle = (backgroundColor?: string) => {
  return {
    backgroundColor: backgroundColor || "unset",
  };
};

export type TestState = "initial" | "form" | "inProgress";

interface Issue {
  link: string | null;
  type: string;
  message: string;
  id: number;
}

interface Suggestion {
  color: string;
  message: string;
}

type RulesEngineResult = {
  issues: Issue[];
  suggestions: Suggestion[];
};

const MainContent = (props: IMainContentProps) => {
  const {
    classes,
    backgroundColor,
    hidePoweredByRTC,
    storeTheme,
    config,
    testResult,
    logs,
    isReady,
    createDate,
    uuid,
    testIsLoading,
    email,
    fields,
    tests,
    onStartTests,
    testsList,
    scoringResult,
    rulesEngineResult,
    downloadPdf,
    theme,
  } = props;
  const { t } = useTranslation(["tests"]);

  const [testState, setTestState] = useState<TestState>("initial");
  const [values, setValues] = React.useState<IValueState>({});
  const [companionModalShow, setCompanionModalShow] = useState<boolean>(false);

  const [showMore, setShowMore] = useState(false);

  const testsContainerRef = useRef<HTMLDivElement | null>(null);

  const { isCompanionForced, isCompanionSuggested } = getCompanionConfig(config);
  useEffect(() => {
    const valuesState: IValueState = {};
    const backwardCompatibilityValues = tests || {};
    const queryParams = getQueryParameters();

    fields?.forEach((field) => {
      valuesState[field.name] = backwardCompatibilityValues[field.name] || queryParams[field.name] || "";
    });

    setValues(valuesState);
  }, [fields]);

  const startTest = (testsList: string[], config: any) => {
    config = {
      ...config,
      fieldsValues: values,
    };
    onStartTests(testsList, config);
    setTestState?.("inProgress");
  };

  const testReload = () => {
    if (isCompanionForced || isCompanionSuggested) {
      const isChrome = navigator.userAgent.includes("Chrome") && typeof chrome !== "undefined" && chrome.runtime;
      if (isChrome) {
        return new Promise((resolve, reject) => {
          chrome.runtime.sendMessage(extensionId, { action: "GET_EXTENSION_INFO" }, (response) => {
            if (!chrome.runtime.lastError) {
              setCompanionModalShow(false);
              startTest(testsList, config);
              setShowMore(false);
              resolve(true);
            } else {
              setCompanionModalShow(true);
              resolve(false);
            }
          });
        });
      } else {
        setCompanionModalShow(true);
        return false;
      }
    } else {
      setCompanionModalShow(false);
      startTest(testsList, config);
      setShowMore(false);
      return true;
    }
  };

  // commenting the actual condition and hiding the hidePermissionModal for https://redmine.testrtc.com/issues/9451
  // let hidePermissionModal = useMediaDevices(config);
  let hidePermissionModal = true;
  const [hideInstallExtensionModal, setHideInstallExtensionModal] = useState<boolean>(!isCompanionForced);

  let checkExtension = useExtensionTracker();

  const showExtension = (state: boolean) => {
    setHideInstallExtensionModal(state);
  };

  let scoringStatus = scoringResult?._global.status;
  const statusColor =
    scoringStatus === "good"
      ? theme.newPalette.success.main
      : scoringStatus === "poor"
        ? theme.newPalette.warning.main
        : scoringStatus === "fail"
          ? theme.newPalette.error.main
          : undefined;

  const statusTitle =
    scoringStatus === "good"
      ? t("tests:testStatus.good")
      : scoringStatus === "poor"
        ? t("tests:testStatus.fair")
        : scoringStatus === "fail"
          ? t("tests:testStatus.critical")
          : undefined;
  const testStatus = testResult?.status;

  const chartClass =
    testStatus === "green"
      ? classes.successChart
      : testStatus === "yellow"
        ? classes.warningChart
        : testStatus === "red"
          ? classes.errorChart
          : undefined;

  const chartTitle =
    testStatus === "green"
      ? t("tests:testStatus.good")
      : testStatus === "yellow"
        ? t("tests:testStatus.fair")
        : testStatus === "red"
          ? t("tests:testStatus.poor")
          : undefined;

  const titleColor =
    testStatus === "green"
      ? theme.newPalette.success.main
      : testStatus === "yellow"
        ? theme.newPalette.warning.main
        : testStatus === "red"
          ? theme.newPalette.error.main
          : undefined;

  useEffect(() => {
    if (showMore && testsContainerRef.current && !config?.showResultsFirst) {
      testsContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showMore]);

  useEffect(() => {
    if (config?.forceShowResults || config?.options?.forceShowResults) {
      setShowMore(true);
    }
  }, [config]);

  const findCodeLinkById = (issue: Issue) => {
    if (issue.link) {
      return issue.link;
    }
    const match = StatusCodes.find((statusCode) => statusCode.statusCode === issue.id);
    return match ? match.codeLink : "#";
  };

  const hideOpenNewIcon = config?.theme === "salesloft" || config?.theme === "dialpad" || config?.theme === "talkdesk";
  const isTalkdesk = config?.theme?.startsWith("talkdesk");

  let score = isTalkdesk
    ? scoringResult?.versions?.ten || scoringResult?.versions?.["10"]
      ? (scoringResult?.versions?.ten || scoringResult?.versions?.["10"])?.scaledScore
      : (scoringResult?.versions?.seven || scoringResult?.versions?.["7"])?.scaledScore
    : scoringResult?._global.scaledScore;

  const isMac = testResult?.hardwareAvailability?.CPU?.modelName.toLowerCase().includes("apple");

  const customColor = storeTheme !== undefined ? colors[storeTheme] : null;
  const colorPalette = theme?.newPalette.isDark ? customColor?.darkMode : customColor?.lightMode;

  const getInsightIcon = (rule: Issue) => {
    const map = {
      "Error": <LogErrorIcon/>,
      "Critical": <LogErrorIcon/>,
      "Warning": <LogWarningIcon/>,
      "Fair": <LogWarningIcon/>,
      "Information": <InfoIcon/>,
    };

    return map[rule.type] || null;
  };

  return (
    <LogModalProvider>
      {config?.useNewWidgetDesign ? (
        testResult && !testResult.checkBrowser?.canRun ? (
          <FailedBrowser config={config}/>
        ) : (
          <div
            className={`${classes.backgroundColor} ${classes.contentWrapper}`}
            style={{
              height: showMore ? "100vh" : "auto",
              flexDirection: config?.showResultsFirst ? "column-reverse" : "column",
              background: colorPalette ? colorPalette.background : "",
            }}
          >
            {testResult &&
              (!testResult.checkBrowser?.canRun ? (
                <FailedBrowser/>
              ) : (
                Object.keys(testResult).length > 0 && (
                  <div className={classes.insightWrapper}>
                    {uuid && (
                      <Information email={email} runTime={createDate} isReady={isReady} testIsLoading={testIsLoading}
                                   uuid={uuid} classes={classes}/>
                    )}
                    <div className={classes.insightContainer}>
                      {uuid ? (
                        scoringResult === undefined ? (
                          <LoadingSpin classes={classes}/>
                        ) : (
                          <>
                            <div className={classes.chartContainer}>
                              {scoringResult && scoringResult._global.scaledScore === 0 ? (
                                <TestStatusChart
                                  chartClass={chartClass}
                                  chartTitle={chartTitle}
                                  titleColor={titleColor}
                                  scoringResult={scoringResult}
                                  config={config}
                                  hideOpenNewIcon={hideOpenNewIcon}
                                />
                              ) : (
                                <ScoreDonutChart
                                  x={score}
                                  activeColor={statusColor}
                                  statusTitle={statusTitle}
                                  scoringResult={scoringResult}
                                  config={config}
                                  hideOpenNewIcon={hideOpenNewIcon}
                                />
                              )}
                              <TestReloadButton testReload={testReload} classes={classes} createDate={createDate}/>
                            </div>
                            {companionModalShow && <CompanionModal skip={isCompanionSuggested ? testReload : undefined}
                                                                   open={companionModalShow}/>}
                            {rulesEngineResult?.issues?.length > 0 && (
                              <div className={classes.logContainer}>
                                {rulesEngineResult?.issues.map((issue, index) => {
                                  if (isMac && issue.message.includes("Low memory available")) {
                                    return null;
                                  }
                                  const codeLink = findCodeLinkById(issue);
                                  return (
                                    <div className={classes.suggestionContainer} key={index}>
                                      {getInsightIcon(issue)}
                                      <div className={classes.suggestionText}>
                                        <Typography variant="body1">{issue.message}</Typography>
                                      </div>
                                      {!hideOpenNewIcon && (
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={codeLink}
                                          className={`${classes.btnOverride} ${classes.openNewIcon} hide-for-image`}
                                        >
                                          <OpenInNewIcon/>
                                        </a>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </>
                        )
                      ) : (
                        <div className={classes.statusChartContainer}>
                          <TestStatusChart chartClass={chartClass} chartTitle={chartTitle} titleColor={titleColor}/>
                          <TestReloadButton testReload={testReload} classes={classes} createDate={createDate}/>
                        </div>
                      )}
                    </div>
                    {((testStatus && testStatus?.length > 0) || scoringResult !== undefined) && (
                      <div className={`${classes.showBtnContainer} hide-for-image`}>
                        {!showMore && !config?.showResultsFirst && (
                          <button
                            className={`${classes.btnOverride} ${classes.showBtn}`}
                            onClick={() => setShowMore(!showMore)}
                            data-html2canvas-ignore
                            id="show-more-widgets"
                          >
                            <Typography variant="caption" className={classes.valueColor}>
                              {t("tests:resultPage.show-more")}
                            </Typography>
                            <span
                              style={{
                                transform: showMore ? " rotate(180deg)" : " rotate(0deg)",
                              }}
                            >
                              <ChevronDownIcon/>
                            </span>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                )
              ))}
            <div className={classes.lobbyWrapper} ref={testsContainerRef}>
              <ControlBar
                hideInstallExtensionModal={hidePermissionModal}
                testState={testState}
                setTestState={setTestState}
                onboardingClasses={classes}
                showMore={showMore}
                showExtension={showExtension}
                showCompanionModal={companionModalShow}
                setShowCompanionModal={setCompanionModalShow}
                downloadPdf={downloadPdf}
              />
              {config && config.options.hidewidgets !== "true" && props.testResult && Object.keys(props.testResult).length > 0 && showMore && (
                <React.Fragment>
                  <TestsContainer/>
                </React.Fragment>
              )}
              {showMore && !testIsLoading && !config?.showResultsFirst && testResult && (
                <div className={`${classes.showBtnContainer} hide-for-image`}>
                  <button
                    className={`${classes.btnOverride} ${classes.showBtn}`}
                    onClick={() => setShowMore(!showMore)}
                    style={{
                      marginTop: "32px",
                    }}
                    id="show-less-widgets"
                  >
                    <Typography variant="caption" className={classes.valueColor}>
                      {t("tests:resultPage.show-less")}
                    </Typography>
                    <span
                      style={{
                        transform: showMore ? " rotate(180deg)" : " rotate(0deg)",
                      }}
                    >
                      <ChevronDownIcon/>
                    </span>
                  </button>
                </div>
              )}
              {!hidePoweredByRTC && testResult === null && (
                <div className={classes.poweredBy}>
                  <span>Powered by</span>
                  <img alt="Powered by" src={theme.newPalette.isDark ? "/darkLogo.svg" : "/lightLogo.svg"} width={70}/>
                </div>
              )}
            </div>
            <MessageModal config={config}/>
          </div>
        )
      ) : (
        <div className={`${classes.wrapper} main-content-wrapper-${storeTheme}`} style={wrapperStyle(backgroundColor)}>
          <div className={classes.container}>
            {config && config.options.hidewidgets !== "true" && (
              <React.Fragment>
                <ExplanationMessage/>
                <PrintInfoBox/>
              </React.Fragment>
            )}
            <ControlBar
              hideInstallExtensionModal={hideInstallExtensionModal}
              showExtension={showExtension}
              showCompanionModal={companionModalShow}
              setShowCompanionModal={setCompanionModalShow}
            />
            {config && config.options.hidewidgets !== "true" && (
              <React.Fragment>
                <TestsContainer/>
                <AdditionalTestsContainer/>
              </React.Fragment>
            )}
            {props.config && props.config.options && props.config.options["report"] === "pdf" && props.config.includeLogs && (
              <React.Fragment>
                <div className="html2pdf__page-break"></div>
                <LogsComponent scrollable={false}/>
              </React.Fragment>
            )}
            {props.testIsLoading && <CompanionModal open={!hideInstallExtensionModal}/>}
            {props.testIsLoading && hideInstallExtensionModal && <PermissionModal open={!hidePermissionModal}/>}
            {hidePermissionModal && hideInstallExtensionModal && <ProgressBar/>}

            {!hidePoweredByRTC && (
              <a className={`poweredBy ${classes.powered} ${classes[`powered_${storeTheme}`]}`}
                 href="https://testrtc.com" target="_blank">
                <span className={classes.poweredText}>Powered by</span>
                <img className={classes.poweredLogo} src={logo} alt="logo"/>
              </a>
            )}
          </div>
        </div>
      )}
    </LogModalProvider>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(MainContent)));
