import { Typography, withStyles, withTheme } from "@material-ui/core";
import React from "react";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";

const styles = (theme: any): any => ({
  container: {
    maxWidth: 1230,
    margin: "0 auto",
    width: "100%",
    padding: "0 15px",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    width: "100%",
    flex: 1,
  },
  backgroundColor: {
    background: theme.newPalette.background,
    flex: 1,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "24px",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    gap: "16px",
    maxWidth: "480px",
    "& h1": {
      color: theme.newPalette.text.primary,
      fontSize: "30px",
      fontWeight: 700,
      lineHeight: "37.5px",
      textAlign: "center",
    },
    "& h6": {
      color: theme.newPalette.text.primary,
      maxWidth: "480px",
      textAlign: "center",
      fontSize: "16px",
      lineHeight: "28px",
      letterSpacing: "0.15px",
      fontWeight: 400,
    },
  },
});

interface FailedBrowserProps {
  classes: any;
  config?: TestConfiguration;
  theme?: any;
  colorPalette?: {
    background: string;
    backgroundContainers: string;
    textColor1: string;
    textColor2: string;
    startTestButton: {
      background: string;
    };
    location: string;
  };
}

const FailedBrowser = ({ classes, config, colorPalette, theme }: FailedBrowserProps) => {
  return (
    <div
      className={`${classes.backgroundColor} ${classes.contentWrapper}`}
      style={{
        height: "100vh",
        flexDirection: config?.showResultsFirst ? "column-reverse" : "column",
        background: colorPalette ? colorPalette.background : "",
      }}
    >
      <div className={classes.wrapper}>
        {theme.newPalette.isDark ? <DarkError /> : <LightError />}
        <div className={classes.textContainer}>
          <Typography variant="h1">Test cannot be run</Typography>
          <Typography variant="subtitle2">Your current browser is not supported. Please use Chrome or Edge to run the test.</Typography>
        </div>
      </div>
    </div>
  );
};

export default withTheme()(withStyles(styles)(FailedBrowser));

const DarkError = () => {
  return (
    <svg width="195" height="100" viewBox="0 0 195 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.38672 50.3603C7.38672 47.1282 9.37091 44.5081 11.8185 44.5081H73.8638C76.3114 44.5081 78.2956 47.1282 78.2956 50.3603C78.2956 53.5925 76.3114 56.2126 73.8638 56.2126H11.8185C9.3709 56.2126 7.38672 53.5925 7.38672 50.3603Z"
        fill="#FB5B63"
        fillOpacity="0.08"
      />
      <path
        d="M25.1133 69.8672C25.1133 66.635 27.0975 64.0149 29.5451 64.0149H91.5904C94.038 64.0149 96.0222 66.635 96.0222 69.8672C96.0222 73.0993 94.038 75.7194 91.5904 75.7194H29.5451C27.0975 75.7194 25.1133 73.0993 25.1133 69.8672Z"
        fill="#FB5B63"
        fillOpacity="0.08"
      />
      <path
        d="M87.1592 50.3603C87.1592 47.1282 89.1434 44.5081 91.591 44.5081H153.636C156.084 44.5081 158.068 47.1282 158.068 50.3603C158.068 53.5925 156.084 56.2126 153.636 56.2126H91.591C89.1434 56.2126 87.1592 53.5925 87.1592 50.3603Z"
        fill="#FB5B63"
        fillOpacity="0.08"
      />
      <path
        d="M166.933 50.3603C166.933 47.1282 168.917 44.5081 171.364 44.5081H190.569C193.017 44.5081 195.001 47.1282 195.001 50.3603C195.001 53.5925 193.017 56.2126 190.569 56.2126H171.364C168.917 56.2126 166.933 53.5925 166.933 50.3603Z"
        fill="#FB5B63"
        fillOpacity="0.08"
      />
      <path
        d="M0 69.8672C0 66.635 1.98419 64.0149 4.4318 64.0149H11.8181C14.2658 64.0149 16.2499 66.635 16.2499 69.8672C16.2499 73.0993 14.2658 75.7194 11.8181 75.7194H4.4318C1.98419 75.7194 0 73.0993 0 69.8672Z"
        fill="#FB5B63"
        fillOpacity="0.08"
      />
      <path
        d="M100.454 30.8523C100.454 27.6202 102.438 25 104.886 25H166.931C169.379 25 171.363 27.6202 171.363 30.8523C171.363 34.0844 169.379 36.7045 166.931 36.7045H104.886C102.438 36.7045 100.454 34.0844 100.454 30.8523Z"
        fill="#FB5B63"
        fillOpacity="0.08"
      />
      <path
        d="M104.887 69.8672C104.887 66.635 106.871 64.0149 109.319 64.0149H171.364C173.811 64.0149 175.796 66.635 175.796 69.8672C175.796 73.0993 173.811 75.7194 171.364 75.7194H109.319C106.871 75.7194 104.887 73.0993 104.887 69.8672Z"
        fill="#FB5B63"
        fillOpacity="0.08"
      />
      <path
        d="M20.6826 30.8523C20.6826 27.6202 22.6668 25 25.1144 25H87.1597C89.6073 25 91.5915 27.6202 91.5915 30.8523C91.5915 34.0844 89.6073 36.7045 87.1597 36.7045H25.1144C22.6668 36.7045 20.6826 34.0844 20.6826 30.8523Z"
        fill="#FB5B63"
        fillOpacity="0.08"
      />
      <circle cx="97.5" cy="50" r="50" fill="#243544" />
      <circle cx="97.5" cy="50" r="50" fill="#FB5B63" fillOpacity="0.08" />
      <circle cx="97.5" cy="50" r="48.5" stroke="#FB5B63" strokeOpacity="0.5" strokeWidth="3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.687 22.0395H93.1139C90.1654 22.0395 87.9071 24.6618 88.3445 27.5776L92.4689 55.0733C92.835 57.5144 94.9319 59.3202 97.4002 59.3202C99.8685 59.3202 101.965 57.5144 102.332 55.0733L106.456 27.5776C106.893 24.6618 104.635 22.0395 101.687 22.0395ZM93.1139 18.75C88.1543 18.75 84.3557 23.1609 85.0914 28.0655L89.2158 55.5613C89.8235 59.6126 93.3036 62.6096 97.4002 62.6096C101.497 62.6096 104.977 59.6126 105.585 55.5613L109.709 28.0656C110.445 23.1609 106.646 18.75 101.687 18.75H93.1139Z"
        fill="#FB5B63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.4006 77.9605C99.5201 77.9605 101.238 76.2423 101.238 74.1228C101.238 72.0033 99.5201 70.2851 97.4006 70.2851C95.2811 70.2851 93.5629 72.0033 93.5629 74.1228C93.5629 76.2423 95.2811 77.9605 97.4006 77.9605ZM97.4006 81.25C101.337 81.25 104.528 78.059 104.528 74.1228C104.528 70.1866 101.337 66.9956 97.4006 66.9956C93.4644 66.9956 90.2734 70.1866 90.2734 74.1228C90.2734 78.059 93.4644 81.25 97.4006 81.25Z"
        fill="#FB5B63"
      />
    </svg>
  );
};

const LightError = () => {
  return (
    <svg width="195" height="100" viewBox="0 0 195 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.38672 50.3603C7.38672 47.1282 9.37091 44.5081 11.8185 44.5081H73.8638C76.3114 44.5081 78.2956 47.1282 78.2956 50.3603C78.2956 53.5925 76.3114 56.2126 73.8638 56.2126H11.8185C9.3709 56.2126 7.38672 53.5925 7.38672 50.3603Z"
        fill="#D32F2F"
        fillOpacity="0.04"
      />
      <path
        d="M25.1133 69.8672C25.1133 66.635 27.0975 64.0149 29.5451 64.0149H91.5904C94.038 64.0149 96.0222 66.635 96.0222 69.8672C96.0222 73.0993 94.038 75.7194 91.5904 75.7194H29.5451C27.0975 75.7194 25.1133 73.0993 25.1133 69.8672Z"
        fill="#D32F2F"
        fillOpacity="0.04"
      />
      <path
        d="M87.1592 50.3603C87.1592 47.1282 89.1434 44.5081 91.591 44.5081H153.636C156.084 44.5081 158.068 47.1282 158.068 50.3603C158.068 53.5925 156.084 56.2126 153.636 56.2126H91.591C89.1434 56.2126 87.1592 53.5925 87.1592 50.3603Z"
        fill="#D32F2F"
        fillOpacity="0.04"
      />
      <path
        d="M166.933 50.3603C166.933 47.1282 168.917 44.5081 171.364 44.5081H190.569C193.017 44.5081 195.001 47.1282 195.001 50.3603C195.001 53.5925 193.017 56.2126 190.569 56.2126H171.364C168.917 56.2126 166.933 53.5925 166.933 50.3603Z"
        fill="#D32F2F"
        fillOpacity="0.04"
      />
      <path
        d="M0 69.8672C0 66.635 1.98419 64.0149 4.4318 64.0149H11.8181C14.2658 64.0149 16.2499 66.635 16.2499 69.8672C16.2499 73.0993 14.2658 75.7194 11.8181 75.7194H4.4318C1.98419 75.7194 0 73.0993 0 69.8672Z"
        fill="#D32F2F"
        fillOpacity="0.04"
      />
      <path
        d="M100.454 30.8523C100.454 27.6202 102.438 25 104.886 25H166.931C169.379 25 171.363 27.6202 171.363 30.8523C171.363 34.0844 169.379 36.7045 166.931 36.7045H104.886C102.438 36.7045 100.454 34.0844 100.454 30.8523Z"
        fill="#D32F2F"
        fillOpacity="0.04"
      />
      <path
        d="M104.887 69.8672C104.887 66.635 106.871 64.0149 109.319 64.0149H171.364C173.811 64.0149 175.796 66.635 175.796 69.8672C175.796 73.0993 173.811 75.7194 171.364 75.7194H109.319C106.871 75.7194 104.887 73.0993 104.887 69.8672Z"
        fill="#D32F2F"
        fillOpacity="0.04"
      />
      <path
        d="M20.6826 30.8523C20.6826 27.6202 22.6668 25 25.1144 25H87.1597C89.6073 25 91.5915 27.6202 91.5915 30.8523C91.5915 34.0844 89.6073 36.7045 87.1597 36.7045H25.1144C22.6668 36.7045 20.6826 34.0844 20.6826 30.8523Z"
        fill="#D32F2F"
        fillOpacity="0.04"
      />
      <circle cx="97.5" cy="50" r="50" fill="white" />
      <circle cx="97.5" cy="50" r="50" fill="#D32F2F" fillOpacity="0.04" />
      <circle cx="97.5" cy="50" r="48.5" stroke="#D32F2F" strokeOpacity="0.5" strokeWidth="3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.687 22.0395H93.1139C90.1654 22.0395 87.9071 24.6618 88.3445 27.5776L92.4689 55.0733C92.835 57.5144 94.9319 59.3202 97.4002 59.3202C99.8685 59.3202 101.965 57.5144 102.332 55.0733L106.456 27.5776C106.893 24.6618 104.635 22.0395 101.687 22.0395ZM93.1139 18.75C88.1543 18.75 84.3557 23.1609 85.0914 28.0655L89.2158 55.5613C89.8235 59.6126 93.3036 62.6096 97.4002 62.6096C101.497 62.6096 104.977 59.6126 105.585 55.5613L109.709 28.0656C110.445 23.1609 106.646 18.75 101.687 18.75H93.1139Z"
        fill="#D32F2F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.4006 77.9605C99.5201 77.9605 101.238 76.2423 101.238 74.1227C101.238 72.0032 99.5201 70.285 97.4006 70.285C95.2811 70.285 93.5629 72.0032 93.5629 74.1227C93.5629 76.2423 95.2811 77.9605 97.4006 77.9605ZM97.4006 81.2499C101.337 81.2499 104.528 78.059 104.528 74.1227C104.528 70.1865 101.337 66.9955 97.4006 66.9955C93.4644 66.9955 90.2734 70.1865 90.2734 74.1227C90.2734 78.059 93.4644 81.2499 97.4006 81.2499Z"
        fill="#D32F2F"
      />
    </svg>
  );
};
