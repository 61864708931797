import React from "react";
import { IPing, IPingAll, ITestsResult } from "twillio-tests/core/TestResults";
import { IColorThresholds } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../../helpers/utils";
import { useTranslation } from "react-i18next";
import { Typography, withStyles, withTheme } from "@material-ui/core";
import TestHeader from "../../TestHeader";

interface IPingTestProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  classes?: any;
}

const initialValues: IPing = {
  ping: null,
  region: null,
};

const findBest = (arr: IPingAll): IPing | undefined => {
  if (Array.isArray(arr)) {
    const numbers = arr.map(({ ping }) => Number(ping));
    const best = Math.max.apply(Math, numbers);

    return arr.find((item) => {
      if (typeof item === "undefined") {
        return;
      }
      return Number(item.ping) === best;
    });
  }
};

const styles = (theme: any): any => ({
  pingWrapper: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    "& > :not(:first-child)": {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderTop: `1px solid ${theme.newPalette.other.divider}`,
    },
  },
  resultRow: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 0",
  },
  valueTitle: {
    fontSize: "12px",
    lineHeight: "14.4px",
    letterSpacing: "0.4px",
    color: theme.newPalette.text.primary,
  },
  valueColor: {
    color: theme.newPalette.text.primary,
  },
  unit: {
    display: "inline-block",
    color: theme.newPalette.text.secondary,
  },
});

const NewPingTest = (props: IPingTestProps) => {
  const { mainColor, testResult, theme, colorThresholds, knowledgeUrl, estimateTime, classes } = props;

  const pingTestResult = testResult ? testResult.pingTest : null;

  const pingTest = pingTestResult ? pingTestResult.best || (pingTestResult.all && findBest(pingTestResult.all)) : initialValues;

  const currentThresholds = colorThresholds ? colorThresholds.Ping : null;

  const { ping, region, error } = pingTest;

  const defaultProps = {
    mainColor,
    color: "#243544",
    formatting: formatNumber,
  };
  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: "#243544",
  };
  const { t } = useTranslation(["common", "tests"]);

  const getColor = (value: number | string | null, threshold: any) => {
    if (threshold) {
      const color = getColorValue(
        {
          ...colorTypes,
          value,
          warningValue: threshold.warning,
          errorValue: threshold.error,
        },
        threshold.isLess
      );
      return color || defaultProps.color;
    } else {
      return defaultProps.color;
    }
  };

  const pingColor =
    currentThresholds && currentThresholds.ping
      ? getColorValue(
          {
            ...colorTypes,
            value: ping,
            warningValue: currentThresholds.ping.warning,
            errorValue: currentThresholds.ping.error,
          },
          currentThresholds.ping.isLess
        )
      : defaultProps.color;

  return (
    <TestHeader
      title={t("tests:ping.title")}
      tooltip={t("tests:ping.tooltip")}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      // testResult={testResult}
      testName={props.testName}
    >
      <div className={classes.pingWrapper}>
        <div className={classes.resultRow} style={{ paddingTop: 0 }} id="PBT-r-region">
          <Typography className={classes.valueTitle}>{t("tests:ping.region")}</Typography>
          <Typography variant="h3" className={classes.valueColor} id="PBT-c-region">
            {region !== null ? region : "-"}{" "}
          </Typography>
        </div>
        <div className={classes.resultRow} id="PBT-r-ping">
          <Typography className={classes.valueTitle}>{t("tests:ping.rtt")}</Typography>
          <Typography variant="h3" className={classes.valueColor} id="PBT-c-ping">
            {ping === -1 ? "-" : formatNumber(Number(ping))}{" "}
            <Typography variant="caption" className={classes.unit}>
              ms
            </Typography>
          </Typography>
        </div>
      </div>
    </TestHeader>
  );
};

export default withTheme()(withStyles(styles)(NewPingTest));
