import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";
import TestHeader from "../TestHeader";
import { LocationIco } from "../../icons";
import { RenderValues } from "../helpers/utils";
import { formatNumber } from "../../../helpers/utils";
import { IDeviceState, ITestsResult } from "twillio-tests/core/TestResults";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import { Typography, withStyles } from "@material-ui/core";
import { AudioOff, AudioOn } from "./NewTests/UI/Icons";
import ResultRow from "./UI/ResultRow";
import "../TestsContainer.scss";

interface ILocationTestProps {
  mainColor: string;
  testResult?: ITestsResult;
  theme: any;
  currentTheme?: string;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  showInMap?: boolean;
  config?: TestConfiguration;
  classes?: any;
}

const styles = (theme: any): any => ({
  testContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    padding: "16px",
    gap: "16px",
  },
  deviceStateSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
  },
  deviceStateResult: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "4px",
  },
  titleColor: {
    color: theme.newPalette.text.primary,
    textTransform: "capitalize",
  },
  stateText: {
    color: theme.newPalette.text.secondary,
    textTransform: "capitalize",
  },
  resultSection: {
    display: "flex",
    flexDirection: "column",
    "& > :not(:first-child)": {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderTop: `1px solid ${theme.newPalette.other.divider}`,
    },
  },
});

const initialValues = {
  audio: "-",
  device: "-",
  network: "-",
};
let mediaPathFlag: boolean = true;
const DeviceStateTest = (props: ILocationTestProps) => {
  const { mainColor, testResult, theme, knowledgeUrl, estimateTime, testName, showInMap, currentTheme, config, classes } = props;
  currentTheme === "livevox" ? (mediaPathFlag = false) : (mediaPathFlag = true);
  const deviceStateTest: IDeviceState = testResult && testResult.deviceState ? testResult.deviceState : initialValues;

  const region = testResult && testResult.testCall && testResult.testCall.region ? testResult.testCall.region : "-";

  const defaultProps = {
    mainColor,
    color: theme.customColors.successColor,
    formatting: formatNumber,
  };

  const { t } = useTranslation(["common", "tests"]);

  const deviceName = deviceStateTest.device;
  const authorization = deviceStateTest.audio !== "-" ? t(`tests:devicestate.${deviceStateTest.audio}`) : "-";
  const network = deviceStateTest.network;

  return (
    <TestHeader
      icon={<LocationIco />}
      title={t("tests:devicestate.title")}
      tooltip={t("tests:devicestate.tooltip")}
      errorsCount={deviceStateTest.audio === "blocked" ? 1 : 0}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
    >
      {config?.useNewWidgetDesign ? (
        <div className={classes.testContainer}>
          <div className={classes.deviceStateSection} id="DST-r-authorization">
            <Typography variant="body1" className={classes.titleColor}>
              {t("tests:devicestate.audio")}
            </Typography>
            <div className={classes.deviceStateResult}>
              {authorization === "Allowed" ? (
                <span
                  style={{
                    color: theme.newPalette.text.primary,
                  }}
                >
                  <AudioOn />
                </span>
              ) : (
                <span
                  style={{
                    color: theme.newPalette.error.main,
                  }}
                >
                  <AudioOff />
                </span>
              )}
              <Typography className={classes.stateText} variant="body2">
                {authorization === "Allowed" ? "Allowed" : "Not Allowed"}
              </Typography>
            </div>
          </div>
          <div className={classes.resultSection}>
            <ResultRow
              label={t("tests:devicestate.network")}
              value={network}
              variant="one-line"
              size="small"
              id="DST-r-network"
              valueId="DST-c-network"
            />
            {mediaPathFlag && region !== "-" && region !== "roaming" && (
              <ResultRow
                label={t("tests:devicestate.mediapath")}
                value={region}
                variant="one-line"
                size="small"
                id="DST-r-region"
                valueId="DST-c-region"
              />
            )}
            <ResultRow
              label={t("tests:devicestate.device")}
              value={deviceName}
              variant="one-line"
              size="small"
              id="DST-r-deviceName"
              valueId="DST-c-deviceName"
            />
          </div>
        </div>
      ) : (
        <div className="tests-card-content tests-card-content--min">
          <table>
            <tbody>
              <tr className="tst-city" id="DST-r-authorization">
                <td>{t("tests:devicestate.audio")}</td>
                <td id="DST-c-authorization">{<RenderValues value={authorization} {...defaultProps} />}</td>
              </tr>
              <tr className="tst-country" id="DST-r-deviceName">
                <td>{t("tests:devicestate.device")}</td>
                <td id="DST-c-deviceName">{<RenderValues value={deviceName} {...defaultProps} />}</td>
              </tr>
              <tr className="tst-org" id="DST-r-network">
                <td>{t("tests:devicestate.network")}</td>
                <td id="DST-c-network">{<RenderValues value={network} {...defaultProps} />}</td>
              </tr>

              {mediaPathFlag && region !== "-" && region !== "roaming" && (
                <tr className="tst-org" id="DST-r-region">
                  <td>{t("tests:devicestate.mediapath")}</td>
                  <td id="DST-c-region">{<RenderValues value={region} {...defaultProps} />}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </TestHeader>
  );
};

export default withTheme()(withStyles(styles)(DeviceStateTest));
