import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";

const styles = (theme: any): any => ({
  customTooltip: {
    backgroundColor: theme.newPalette.isDark ? "rgba(255, 255, 255, 0.92) !important" : "#243544 !important",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "14px",
    color: theme.newPalette.primary.contrast,
    padding: "4px 8px",
    // fontFamily: "Roboto, sans-serif !important",
    textAlign: "center",
  },
  arrowColor: {
    borderTopColor: theme?.newPalette.isDark ? "rgba(255, 255, 255, 0.92) !important" : "#243544 !important",
  },
});

function mapStateToProps(state: any) {
  return {
    testResult: state.tests.testResult,
  };
}

function CustomTooltip({ title, children, classes, estimateTime, testResult, value, theme, ...props }: any) {
  const shouldShowTooltip =
    testResult &&
    ((value && typeof value === "string" && value.trim().length > 0) || value === undefined) &&
    (typeof title === "string" ? title.trim().length > 0 : !!title);

  const tooltipContent = (
    <>
      <div className={`${classes.arrowColor} arrow`} />
      {typeof title === "string" ? title : React.isValidElement(title) ? title : null}
      {estimateTime && (
        <>
          <br />
          {`${estimateTime}s`}
        </>
      )}
    </>
  );
  return shouldShowTooltip ? (
    <Tooltip title={tooltipContent} classes={{ tooltip: classes.customTooltip }} {...props} placement="top">
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
}

export default connect(mapStateToProps)(withTheme()(withStyles(styles)(CustomTooltip)));
